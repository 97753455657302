<template>
	<div>

		<v-skeleton-loader type="card, list-item-two-line" v-if="loading" ></v-skeleton-loader>
		<v-card v-for="article in articles" :key="article.id" :to="getRoute(article.id)">
			<v-row>
				<v-col class="col-12 col-sm-4"><v-img :src="article.bg" :alt="article.title"></v-img></v-col>
				<v-col class="col-12 col-sm-8">
					<v-row>
						<v-col class="col-12 text-subtitle-2">{{ article.date_of_publication }}</v-col>
						<v-col class="col-12 text-uppercase light-blue--text text--darken-2 text-h5">{{ article.title }}</v-col>
						<v-col class="col-12 text-subtitle-1"><span v-html="article.text_short"></span></v-col>
						<v-col class="col-12 d-none"><hr></v-col>
						<v-col class="text-overline d-none">Просмотров: {{ article.views }}</v-col>
						<v-col class="text-overline d-none">Комментировать</v-col>
						<v-col class="d-none">Like</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script type="text/javascript">

export default {
	name: 'Articles',
	props: ['articles', 'loading',],
	
	data () {
		return {}
	},
	methods: {
		getRoute(id){
			return "/post/"+id
		}
	},
}
</script>
